@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;700&display=swap');

html {
    background-color: white;
}

body {
    color: $color-dark-1;

    font-family: 'Fira Code', monospace;
    font-size: 14px;
    font-weight: 400;

    line-height: 1.5;
}

code {
    font-family: 'Fira Code', monospace;
    color: $color-error;
}

a {
    color: $color-primary;
}

img {
    max-width: 90%;
    margin: 1em auto;
}

.card {
    border: 1px solid $color-grey-1;
    border-radius: 20px;
    padding: 2em;
}