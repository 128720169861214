$color-dark-1: #0d1117;
$color-dark-2: #151b22;
$color-dark-3: #21262d;

$color-grey-1: #89929b;
$color-grey-2: #c6cdd5;
$color-grey-3: #ecf2f8;

$color-error: #fa7970;
$color-warn: #faa356;
$color-success: #7ce38b;
$color-primary: #58a6ff;
$color-lilac: #cea5fb;

$color-divider: #21262d;

$color-text-primary: #c9d1d9;
$color-text-secondary: #8b949e;